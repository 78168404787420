import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import komaImage from './image/ojisan.jpg';
import hazureImage from './image/hazure.jpg';
import okSound from './sound/ok.mp3';
import explosionSound from './sound/explosion.mp3';
import bgmSound from './sound/Groove_Galaxy.mp3';

function Game(props) {
  const [moji, setMoji] = useState('ooo');
  const [komas, setKomas] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
  ]);
  const [hazures, setHazures] = useState(
    getUniqueRandomNumbers(props.blankCount)
  );
  const [isGame, setIsGame] = useState(true);
  const audio = new Audio(okSound);
  const explosion = new Audio(explosionSound);

  // はずれの配列が変更されたときにおこる処理
  useEffect(() => {
    if (hazures.length === 0) {
      setTimeout(() => {
        // 3秒後に実行する処理
        props.setPage(3);
      }, 3000); // 3000ミリ秒（3秒）
    }
  }, [hazures]);

  const clickKoma = (koma) => {
    setKomas(komas.map((k) => (k === koma ? 0 : k)));

    if (hazures.some((hazure) => hazure === koma)) {
      // 押したコマがはずれの時の処理
      explosion.play();
      setMoji('はずれ');
      setIsGame(false);
      setTimeout(() => {
        // 3秒後に実行する処理
        setIsGame(true);
      }, 3000); // 3000ミリ秒（3秒）
      setHazures(hazures.filter((k) => k !== koma));
    } else {
      // 押したコマがあたりの時の処理
      audio.play();
      setMoji('OK');
    }
  };
  const clickStartButton = () => {
    props.setPage(1);
  };
  const clickEndButton = () => {
    props.setPage(3);
  };

  function getUniqueRandomNumbers(count) {
    // 1から15の配列を作成
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

    // Fisher-Yatesシャッフルアルゴリズムで配列をシャッフル
    for (let i = numbers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
    }

    // 指定された個数の数字を取得
    return numbers.slice(0, count);
  }
  if (isGame) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Button variant="contained" onClick={clickEndButton} size="small">
          ゲーム終了
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={clickStartButton}
          size="small"
        >
          戻る
        </Button>
        <Box sx={{ border: '1px solid black', marginTop: '20px' }}>
          {komas.map((koma) => {
            return koma !== 0 ? (
              <img
                width="90px"
                onClick={() => clickKoma(koma)}
                src={komaImage}
              />
            ) : (
              <Box
                sx={{ width: '90px', height: '90px', display: 'inline-block' }}
              />
            );
          })}
        </Box>
        <Box sx={{ border: '1px solid black', marginTop: '20px' }}>{moji}</Box>
      </div>
    );
  } else {
    return <img width="100%" src={hazureImage} />;
  }
}

export default Game;
