import { Button } from '@mui/material';

function End(props) {
  const clickStartButton = () => {
    props.setPage(0);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div>お疲れ様</div>
      <Button variant="contained" onClick={clickStartButton}>
        スタート画面に戻る
      </Button>
    </div>
  );
}

export default End;
