import { useState } from 'react';
import Start from './start';
import Setting from './setting';
import Game from './game';
import End from './end';

function Index() {
  const [page, setPage] = useState(0);
  const [blankCount, setBlankCount] = useState(0);

  const displayPage = () => {
    switch (page) {
      case 0:
        return <Start setPage={setPage} />;
      case 1:
        return <Setting setPage={setPage} setBlankCount={setBlankCount} />;
      case 2:
        return <Game setPage={setPage} blankCount={blankCount} />;
      case 3:
        return <End setPage={setPage} />;
      default:
        break;
    }
  };

  return (
    <div style={{ margin: '10%', width: '80vw', height: '80vh' }}>
      {displayPage()}
    </div>
  );
}

export default Index;
