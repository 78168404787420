import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useState } from 'react';
import okSound from './sound/start.mp3';
function Setting(props) {
  const [blankCount, setBlankCount] = useState(1);
  const audio = new Audio(okSound);
  const onChangeBlankCount = (e) => {
    setBlankCount(e.target.value);
  };
  const clickStartButton = () => {
    audio.play();
    props.setBlankCount(blankCount);
    props.setPage(2);
  };
  const clickModoruButton = () => {
    props.setPage(0);
  };
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ width: '100%', height: '30%' }}>ゲーム設定</div>
      <div style={{ width: '100%', height: '30%' }}>
        <div>はずれの数</div>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={blankCount}
          onChange={onChangeBlankCount}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={9}>9</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={11}>11</MenuItem>
          <MenuItem value={12}>12</MenuItem>
          <MenuItem value={13}>13</MenuItem>
          <MenuItem value={14}>14</MenuItem>
          <MenuItem value={15}>15</MenuItem>
        </Select>
        コ
      </div>

      <Button variant="contained" onClick={clickStartButton}>
        ゲームスタート
      </Button>
      <Button color="error" variant="contained" onClick={clickModoruButton}>
        戻る
      </Button>
    </div>
  );
}

export default Setting;
