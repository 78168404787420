import { Button } from '@mui/material';
import titleImage from './image/title.png';
import okSound from './sound/start.mp3';

function Start(props) {
  const audio = new Audio(okSound);
  const clickStartButton = () => {
    audio.play();
    props.setPage(1);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80%',
        }}
      >
        <img src={titleImage} width={'100%'} style={{}} />
      </div>
      <Button fullWidth variant="contained" onClick={clickStartButton}>
        ゲームスタート
      </Button>
    </div>
  );
}

export default Start;
